/* ----------------------Ledger ---------------- */
.transactionMain {
  width: 100%;
  display: flex !important;
  gap: 10px;
  align-items: flex-end;
  margin-top: 10px;
  flex-wrap: wrap;
}
.FromDate {
  display: flex;
  gap: 10px;
  align-items: flex-end;
  flex-wrap: wrap;
  margin-top: 10px;
}

.FDATE {
  font-family: sans-serif;
  gap: 0px;
  /* font-size: 16px; */
}
.SFromDate {
  display: flex;
  gap: 10px;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 40px !important;
}
.epss-btn {
  display: flex;
  margin-top: 20px;
  gap: 10px;
  /* align-items: flex-end; */
  font-size: 2px;
  box-sizing: border-box;
}
.PDS {
  font-size: 13px;
  font-family: sans-serif;
}
@media screen and (max-width: 500px) {
  .epss-btn {
    width: 100%;
    display: flex;
    gap: 10px;
    justify-content: center;
    box-sizing: border-box;
  }
  .search-btn {
    width: 100%;
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .transactionMain {
    display: flex;
    display: table-row;
  }
  .PDS {
    font-size: 12px;
  }
  .SFromDate {
    display: flex;
    gap: 10px;
    align-items: flex-end;
    flex-wrap: wrap;
    margin-top: 40px;
  }
  .FDATE {
    font-family: sans-serif;
    gap: 0px;
    font-size: 12px;
  }
}
