.sub-menu {
  position: absolute;
  background-color: #fff;
  border-radius: 5px;
  transform-origin: 50% -30px;
  right: 1%;
  max-width: 18rem;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  overflow: hidden;
  border: 1px solid rgba(0, 0, 0, 0.15);
}
.menu-list-main {
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
  padding-block: 10px;
}
.menu-header {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 15px;
  background-color: #f5f6fa;
  border-top: 2px solid #9e72ff;
}
.menu-header-title {
  font-size: 16px;
  color: #000;
  font-weight: 600;
}
.menu-header-img {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  object-fit: cover;
}
.submenu-div {
  display: flex;
  align-items: center;
  gap: 10px;
  margin: 5px;
  padding-block: 5px;
  padding-inline: 5px;
  cursor: pointer;
}
:hover.submenu-div {
  background-color: #f5f6fa;
}
.submenu-title {
  font-size: 14px;
  color: #000;
  font-weight: 500;
}
