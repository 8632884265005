.inp-main {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.inp-label {
  margin-bottom: 5px;
  font-weight: 700;
  font-family: "IBM Plex Sans", sans-serif;
  font-size: 14px;
  color: #6f6f6f;
  line-height: 24px;
}
.inp-main2 {
  display: flex;
  align-items: center;
  gap: 10px;
}

.inp-mess {
  margin-bottom: 5px;
  font-weight: 400;
  font-family: "IBM Plex Sans", sans-serif;
  font-size: 12px;
  color: #6f6f6f;
  line-height: 24px;
  padding: 0;
  margin: 0;
}

.inp-error {
  text-align: left;
  /* margin-bottom: 5px; */
  font-weight: 400;
  font-family: "IBM Plex Sans", sans-serif;
  font-size: 14px;
  color: #f00;
  line-height: 24px;
}

.inp {
  outline: none;
  border: none;
  width: 100%;
  height: 100%;
  display: flex;
  padding: 0;
  font-size: 14px;
  color: #000;
  padding-inline: 10px;
  font-family: "IBM Plex Sans", sans-serif;
}

.inp-wrap {
  z-index: 0;
  height: 37px;
  background-color: #fff;
  border: 1px solid #dbdfea !important;
  border-radius: 3px;
  min-width: 120px;
  cursor: text;
}

.drop-select {
  border-radius: 2px;
  overflow: hidden;
  padding-left: 10px;
  padding-right: 28px;
  margin: 0;
  outline: none;
  border: 1px solid #dbdfea !important;
  height: 35px;
  -webkit-appearance: none;
  appearance: none;
  background-color: #fff;
  font-family: "IBM Plex Sans", sans-serif;
  background-image: url("../../assets//down.png");
  background-size: 24px;
  background-repeat: no-repeat;
  background-position: calc(100% - 4px) center;
}

.drop-div {
  width: 100%;
  display: flex;
  flex: 1;
}

#hsn {
  position: absolute !important;
  bottom: -1px !important;
  left: -1px !important;
  background-color: white;
  color: black;
  padding: 0;
  border: 1px solid #ccc; /* optional: add a border */
  z-index: 1; /* ensure the datalist appears above other content */
  max-height: 200px;
}

input[list="hsn"] {
  padding: 5px; /* Add padding to the input box to create space */
}

.ant-select-selector {
  outline: none !important;
  border: none !important;
  width: 100% !important;
  height: 100% !important;
  display: flex !important;
  padding: 0 !important;
  font-size: 14px !important;
  color: #000 !important;
  padding-inline: 10px !important;
  font-family: "IBM Plex Sans", sans-serif !important;
}
.inp:active {
  border: none !important;
  outline: none !important;
}
