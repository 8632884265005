.GST-btn {
  font-family: "IBM Plex Sans", sans-serif;
  font-weight: 700;
  min-height: 35px;
  border: 0px;
  text-align: center;
  cursor: pointer;
  color: white;
  padding: 0px 12px;
  font-size: 0.8rem;
  border-color: #0797ad !important;
  background-color: #08a2b9 !important;
  border-radius: 3px;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  display: flex;
  align-items: center;
  gap: 5px;
}
.GSTR1Mian {
  display: flex;
  gap: 10px;
}
.tb1 {
  display: flex;
  flex-wrap: wrap;
}

.gstr_main {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  gap: 2rem;
  width: 60%;
  max-width: 700px;
  padding : 2rem 0;
  font-size: 14px;
}
.gstr_1 {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  width: 100%;
}
.gstr_1_1 {
  flex: 1;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  padding: 10px;
  border: 1px solid #000; /* Maintain this border */
  border-top: none; /* Prevent overlap with the div above */
}
.gstr_1_1 > div {
  text-align: center;
}
.gstr_1_1:first-child {
  border-top: 1px solid #000; /* Ensure the top border for the first row */
}



@media screen and (max-width: 600px) {
  .GSTR1Mian {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
  }
}
