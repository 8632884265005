/* .list-main {
  display: flex;
  gap: 10px;
  display: flex;
  align-items: center;
  margin-inline: 15px;
  cursor: pointer;
  padding: 10px;
  border-radius: 5px;
} */
.list-main {
  display: flex;
  gap: 13px;
  display: flex;
  align-items: center;
  margin-inline: 15px;
  cursor: pointer;
  padding-inline: 10px;
  padding-block: 5px;
  border-radius: 5px;
  margin-left: 10px;
}
:hover.list-main {
  background-color: #fff;
}
:hover.list-main-1 {
  background-color: #fff;
}
.list-main-active {
  background-color: #fff;
}
.list-icon {
  width: 30px;
  justify-content: center;
  display: flex;
}
.list-title {
  width: 100%;
  opacity: 1;
  transition: 0.4s linear;
  color: #6e82a5;
  overflow: hidden;
  display: inline-block;
}
.list-arrow {
  width: 20px;
  justify-content: center;
  display: flex;
}

.rotate-down {
  transform: rotate(90deg);
  transition-duration: 1s;
}
.rotate-right {
  transform: rotate(0deg);
  transition-duration: 1s;
}
.fs15 {
  font-size: 15px;
  font-weight: 600;
}
.fs14 {
  font-size: 14px;
  font-weight: 600;
}
.fs12 {
  font-size: 13px;
  font-weight: 500;
}
.circle {
  height: 10px;
  width: 10px;
  border-radius: 30px;
  background-color: transparent;
  border: 2px solid #6e82a5;
}
.list-main-single {
  display: flex;
  gap: 4px;
  display: flex;
  align-items: center;
  margin-inline: 15px;
  cursor: pointer;
  padding: 7px;
  border-radius: 5px;
}
:hover.list-main-single {
  background-color: #fff;
}

.mt10 {
  margin-top: 10px;
}
.mt20 {
  margin-top: 20px;
}


.list-main-single-profile{
  display: flex;
  gap: 4px;
  display: flex;
  border-top: 1px solid #6e82a5;
  cursor: pointer;
  padding: 7px 20px;
  color: #000;
  /* border-radius: 5px; */
}
.list-title-profile{
text-align: left;
font-size: 16px;
}