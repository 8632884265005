.radio-main {
  display: flex;
  gap: 10px;
  align-items: center;
}
.radio-circle {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  border: 2px solid #fff;
  cursor: pointer;
}

.radio-circle1 {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  border: 2px solid #2cbfca;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  display: flex;
}
.radio-inner {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: #2cbfca;
}
.radio-label {
  font-weight: 700;
  font-family: "IBM Plex Sans", sans-serif;
  font-size: 14px;
  color: #6f6f6f;
  line-height: 24px;
}
