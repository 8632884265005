.notification-menu {
  position: absolute;
  background-color: #fff;
  border-radius: 5px;
  transform-origin: 50% -30px;
  right: 13%;
  max-width: 18rem;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  overflow: hidden;
  border: 1px solid rgba(0, 0, 0, 0.15);
}
.noti-icon {
  animation: bell 1s 1s both infinite;
}
.noti-bell-icon {
  border-radius: 30px;
  background-color: #9e72ff;
  min-height: 40px;
  min-width: 40px;
  width: 40px;
  justify-content: center;
  align-items: center;
  display: flex;
}
.noti-mess-div {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 10px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
  cursor: pointer;
}
.noti-mess-header {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 15px;
  background-color: #f5f6fa;
  border-top: 2px solid #9e72ff;
  font-size: 18px;
  font-weight: 600;
  color: #212529;
}
.noti-mess-main {
  width: 100%;
  height: 200px;
  overflow-y: scroll;
}
.noti-mess-main::-webkit-scrollbar {
  display: none;
}
.noti-mess-txt {
  font-size: 14px;
  font-weight: 400;
  color: #f00;
}
.noti-mess-box {
  position: absolute;
  background-color: #fff;
  border-radius: 5px;
  transform-origin: 50% -30px;
  right: 13%;
  max-width: 20rem;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  overflow: hidden;
  border: 1px solid rgba(0, 0, 0, 0.15);
}
