.btn-success {
  color: #fff;
  background-color: #18b389;
  border: 1px solid #16a881;
}

.btn {
  font-family: "IBM Plex Sans", sans-serif;
  font-weight: 700;
  min-height: 35px;
  text-align: center;
  cursor: pointer;
  padding: 0px 12px;
  font-size: 0.8rem;

  border-radius: 3px;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  display: flex;
  align-items: center;
  gap: 5px;
}

.btn-info {
  color: #fff;
  background-color: #08a2b9;
  border: 1px solid #0797ad;
}

.btn-info1 {
  color: #fff;
  background-color: #08a2b9;
  border: 1px solid #0797ad;
}

:hover.btn-info {
  background-color: #138496;
  border: 1px solid #117a8b;
}

.btn-save {
  color: #fff;
  background-color: #0971fe;
  border: 1px solid #007bff;
}

:hover.btn-info {
  background-color: #138496;
  border: 1px solid #117a8b;
}

:hover.btn-success {
  background-color: #218838;
  border: 1px solid #1e7e34;
}

.submit-btn {
  color: #fff;
  background-color: #0971fe;
  border: 1px solid #007bff;
}

.submit-btn:hover {
  background-color: #138496;
  border: 1px solid #117a8b;
}

/* CSS by Naveen  */

.Help {
  position: relative;
  /* z-index: 1; */
}

.RP-help {
  min-height: 35px;
  padding: 0px 12px;
  border: 1px solid #0971fe;
  background-color: white;
  border-radius: 0.25rem;
  color: #0971fe;
  font-weight: 700;
  font-size: 0.8rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.RP-help:focus {
  outline: #0971fe;
  background: #0971fe;
  color: white;
}

.RP-help option {
  color: #0a97b1;
  background-color: white;
  margin-top: 1rem;
}

.RP-help option:hover {
  background-color: white;
  color: #9d72ff;
}

.Help-content {
  position: absolute;
  top: 40px;
  right: 3px;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  align-items: center;
  background-color: white;
  text-align: left;
  animation: fadeIn 0.85s;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.help-content-item {
  padding: 0.15rem 1rem;
  background-color: white;
  text-align: left;
  color: #0a97b1;
  cursor: pointer;
  font-size: 13px;
  text-decoration: none;
}

.edit-btn {
  margin-top: 0.25rem;
  color: blue;
  font-size: 20px;
  height: 20px;
  width: 20px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media screen and (max-width: 500px) {
  .btn {
    min-height: 30px;
    padding: 0px 8px;
    font-size: 0.75rem;
  }
  .btn-icon {
    height: 20px !important;
    width: 20px !important;
  }
  .RP-help {
    min-height: 30px;
    padding: 0px 8px;
    font-size: 0.75rem;
  }
}
