@import url("https://fonts.googleapis.com/css?family=Josefin+Sans");

.DayEndText {
  margin: 1rem 0 .5rem 0;
  font-size: 15px;
  color: #526484;
  /* font-family: Cambria, Cochin, Georgia, Times, "Times New Roman", serif; */
}
.GENERATE-BTN {
  text-wrap: nowrap;
  border: 10px;
  font-size: 15px;
  padding: 10px;
  background-color: #17a2b8 !important;
  color: white;
  border-radius: 3px !important;
  font-family: "IBM Plex Sans", sans-serif !important;
}
.GENERATE-BTN:hover {
  background-color: #117a8b !important;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out;
}

button {
  font-family: "IBM Plex Sans", sans-serif;
  font-weight: 600;
  font-size: small !important;
  text-align: center;
  cursor: pointer;
}
