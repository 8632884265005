.sidebar-main {
  height: 100%;
  overflow-x: scroll;
}
.sidebar-main::-webkit-scrollbar {
  display: none;
}
.sidebar-logo {
  height: 4.5rem;
  display: flex;
  align-items: center;
  padding-inline: 13px;
}
.s_logo {
  /* height: 40px; */
  width: 47px;
  object-fit: contain;
}
.s_logotxt {
  height: 2.5rem;
  object-fit: contain;
}
