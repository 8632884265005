.MD-tr {
    width: 100%;
}

.MD-td {
    width: 25%;
}


.MD-td-div {
    width: 100%;
    max-height: 28px;
    margin: .1rem 0;
    padding: .25rem .25rem;
    border: 1px solid rgba(0, 0, 0, 0.158);
    border-radius: .25rem;
    cursor: text;
    font-size: 14px;
    font-weight: 400;
    background-color: rgba(0, 0, 0, 0.089);
    overflow-x: scroll !important;
    overflow-y: hidden !important;
}

.MD-td-div::-webkit-scrollbar {
    display: none;
}

.MD-td-div:focus {
    outline: none;
}

.MD-input {
    padding: .35rem .85rem;
}

.save-btn {
    display: flex;
    justify-content: center;
}

label.label input[type="file"] {
    position: absolute;
    top: -1000px;
}

@keyframes fadeIn {
    0% {
        right: -100px;
    }

    100% {
        right: 3px;
    }
}

.label {
    cursor: pointer;
    border: 1px solid #cccccc;
    border-radius: 5px;
    padding: 6px 15px;
    margin: 5px;
    background: #ffffff;
    display: inline-block;
    height: 35px;
}

.label:hover {
    background: #ffffff;
}

.label:active {
    background: #9fa1a0;
}

.label:invalid+span {
    color: #000000;
}

.label:valid+span {
    color: #ffffff;
}