.bg-container {
  display: flex;
  min-height: 100vh;
  width: 100vw;
  background-image: url(../../assets/mylpg-advise.png),
    url(../../assets/mylpg-grow.png);
  background-repeat: no-repeat, no-repeat;
  background-attachment: fixed, fixed;
  background-size: 300px, 368px;
  background-position: left bottom, right bottom;
  background-color: #f5f6fa;
  justify-content: center;
  align-items: center;
  overflow: scroll;
}

@media (max-width: 576px) {
  .bg-container {
    display: flex;
    min-height: 100vh;
    width: 100vw;
    background-image: url(../../assets/mylpg-advise.png),
      url(../../assets/mylpg-grow.png);
    background-repeat: no-repeat, no-repeat;
    background-attachment: fixed, fixed;
    background-size: 0px, 0px;
    background-position: left bottom, right bottom;
    background-color: #f5f6fa;
    justify-content: center;
    align-items: center;

    overflow: scroll;
  }
}

/* Medium screens (577px to 992px) */
@media (min-width: 577px) and (max-width: 992px) {
}

/* Large screens (993px and above) */
@media (min-width: 993px) {
}
