.home_title_container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: .51rem;
}
.wel {
  font-size: 1rem;
  margin-bottom: 0.5rem;
  color: #8094ae;
  font-weight: 400;
}
.name_data {
  font-size: 35px;
  color: #364a63;
  font-weight: 400;
  text-transform: capitalize;
}
.desc {
  color: #526484;
}
.home_2nd{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: .25rem;
    margin-top: 2rem;
    width: 100%;
}
.title_over{
    font-size: 20px;
    line-height: 26px;
    color: #6f6f6f;
}
.home_content{
    width: 100%;
    display: flex;
    gap: 1.5rem;
}
.cards{
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 100%;
    border-radius: .5rem;
}
.card_1{
    width: calc(100% - 0rem);
    padding: 1rem 2rem;
    display: flex;
    flex-direction: column;
    gap: .25rem;
    justify-content: center;
    align-items: flex-start;
    height: 96px;
}
.progress{
    width: 100%;
    background-color: #FFFFFF;
    border-radius: 1.25rem;
    margin: 5px 0;
}
.progress_bar {
	background-color: #4E75D1;
}

@media screen and (max-width:500px) {
  .home_content{
    flex-direction: column;
    width: calc(100% - 2rem);
    margin: 1rem 1rem;
  }
  .home_2nd{
    margin-top: 1rem;
  }
  
}