.runPayroll-btn {
  display: flex;
  text-wrap: nowrap;
  width: auto;
  padding: 10px;
  background-color: blue;
  color: white;
  border-radius: 5px;
}

.salary-sheet-btn {
  display: flex;
  width: auto;
  text-wrap: nowrap;

  background-color: grey;
  color: white;
  padding: 10px;
  border-radius: 5px;
}

.ReportButtons {
  display: flex;
  width: auto;
  text-wrap: nowrap;
  background-color: #808080;
  color: white;
  padding: 10px;
  border: 0px;

  border-radius: 5px;
}
.ReportButtons:focus {
  background-color: #2196f3 !important;
}
.tb1 {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  align-items: center;
  justify-items: flex-end;
}
.MonYear {
  display: flex;
  flex-wrap: wrap;
  justify-items: flex-end;
  align-items: center;
  gap: 10px;
}
.DR {
  display: flex;
  flex-wrap: wrap;
}
.EXECUTE-btn {
  display: flex;
  text-wrap: nowrap;
  border: 0px;
  font-size: 15px;
  padding: 10px;
  font-family: Cambria, Cochin, Georgia, Times, "Times New Roman", serif;
  background-color: #17a2b8;
  color: white;
  border-radius: 5px;
}
.SEARCH-btn {
  display: flex;
  text-wrap: nowrap;
  border: 0px;
  font-size: 15px;
  padding: 10px;
  background-color: #2196f3;
  color: white;
  border-radius: 5px;
}
.SUBMIT-btn {
  display: flex;
  text-wrap: nowrap;
  border: 0px;
  border: 0px;
  font-size: 15px;
  padding: 10px;
  background-color: #2196f3;
  color: white;
  font-family: Cambria, Cochin, Georgia, Times, "Times New Roman", serif;

  border-radius: 5px;
}
.ReportMenu-btn {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
}
.search-btn {
  display: flex;
  align-items: center !important;
}
.payshow-btn {
  padding: 5px;
  text-align: center;
  font-size: 2px;
  box-sizing: border-box;
}
@media screen and (max-width: 600px) {
  .ReportsMain {
    display: flex;
    flex-direction: column;
  }
}
