.header-main {
  align-items: center;
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 0 1rem;
}
.header-right {
  align-items: center;
  display: flex;
  gap: 20px;
  padding-block: 10px;
}

.nav-btn {
  display: flex;
  gap: 1rem;
  width: 100%;
}
.nav-btn-1 {
  padding: 15px 0.5rem;
  line-height: 20px;
  height: 35px;
  background-color: #eef5ff;
  border: 1px solid #e5e9f2;
  border-radius: 4px;
  font-weight: bold;
  text-transform: uppercase;
  text-align: center;
  display: flex;
  align-items: center;
  font-size: 14px;
  letter-spacing: 1px;
  cursor: pointer;
  color: #6f6f6f;
  text-decoration: none;
}

.nav-btn-1:hover{
  color: white;
  background-color: #007bff;
}
.nav-btn-1-focus{
  color: white;
  background-color: #007bff;
}

@media screen and (max-width:1200px) {
  .nav-btn {
    gap: .25rem;
  }
}

@media screen and (max-width:1050px) {
  .nav-btn {
    display: none;
  }
}

@media screen and (max-width:500px) {
  .menu-user-id, .menu-down-arr{
    display: none;
  }
  .header-right{
    padding:0px ;
    gap: 10px;
  }
  .page-head{
    flex-direction: column;
    gap: .5rem;
    margin-top: 1rem;
    
  }
  .profile_emp_head{
    padding: 0 5px ;
    flex-direction: row !important;
  }
  
  .page-head > div{
    width: 100% !important;
    display: flex !important;
    justify-content: flex-start !important;
    align-items: flex-start !important;
    flex-wrap: wrap;
  }
  .profile_emp_head > div{
    width: auto !important;
  }
}