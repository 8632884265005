.layout-main {
  width: 100vw;
  height: 100vh;
  /* background-color: red; */
  display: flex;
  position: fixed;
  /* overflow: hidden; */
  
}
.layout-profile{
  width: calc(100% - 2rem) ;
  box-shadow: 0px 0px 12px 0px #0000003D;
margin: 1rem;
display: flex;
border-radius: 12px;
/* position: fixed; */
background-color: #fff !important;

}
.profile_main{
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
}
.profile_pic{
  width: 100%;
  min-height: 320px;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  justify-content: flex-start;
  align-items: center;
  padding: 2rem 0;
}
.profile_pic_main{
  height: 150px;
  width: 150px;
  position: relative;
}
.profile_pic_main > img{
width: 100%;
height: 100%;
border-radius: 50%;
}
.camera_icon_div{
  position: absolute;
  height: 35px;
  width: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #F3F2F2;
  border-radius: 50%;
  bottom: 0;
  right: 10px;
  cursor: pointer;
}
.firm_name{
  font-size: 23px;
  font-weight: bold;
}

.layout-sidebar {
  background-color: #f5f6fa;
  border-right: 1px solid #e5e9f2;
  /* overflow-x: scroll; */
  /* height: 100%; */
}

.layout-body {
  height: 100%;
  background-color: #fff;
  display: flex;
  width: 100%;
  overflow: hidden;
  flex-direction: column;
}

.layout-header {
  height: 4.5rem;
  width: 100%;
  background-color: #fff;
  border-bottom: 1px solid #e5e9f2;
  display: flex;
}

.layout-outlet {
  background-color: #fff;
  width: 100%;
  height: calc(100% - 4.5rem);
  overflow-x: scroll !important;
}


.layout-outlet2 {
  background-color: #fff;
  /* width: calc(100% - 15rem - 40px); */
  overflow-x: scroll !important;
}
.layout-outlet::-webkit-scrollbar {
  display: none;
}
.sidebar-new{
  display: none;
}
.ant-drawer-header-title{
  display: flex !important;
  justify-content: flex-end !important;
}
.ant-drawer-body{
  padding: 0 !important;
}
.ant-drawer-header{
  padding: 10px 0px !important;
  font-size: 20px !important;
  border-bottom: none !important;
}
.ant-drawer-body .ant-drawer-header{
  background-color: #dbdbdb !important;
}



@media (max-width: 906px) {
  .layout-sidebar {
    display: none;
  }
  .sidebar-new{
    display: block;
  }

  .layout-outlet2 {
    width: calc(100%);
  }
}

@media screen and (max-width:500px) {
  .layout-header{
    height: auto;
  }
  .layout-outlet{
    /* margin-bottom: 3.5rem; */

  }

  
}
