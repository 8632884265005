.table-container {
  /* overflow: auto; */
  width: 100%;
}
.tableScroll {
  overflow-x: scroll !important;
}

.tableScroll::-webkit-scrollbar {
  height: 5px;
}

.tableScroll::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 0.25rem;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
  border: 1px solid #ddd;
}

th,
td {
  padding: 8px;
  text-align: center;
}

tr {
  border: 1px solid #ddd;
}

th {
  background-color: #1b64af;
  color: #c9ddf1;
  font-size: 12px;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 0.12em;
  border: 1px solid #418ad6;
  height: 35px;
}

td {
  background-color: #fff;
  color: #526484;
  font-size: 13px;
  font-weight: 500;
}

.table-nodata {
  font-size: 18px;
  color: #000;
  padding: 2rem;
  text-align: center;
  font-weight: 500;
  width: 100%;
  border: 1px solid #ddd;
}
.table-nodata2 {
  margin-top: 1rem;
  font-size: 18px;
  color: #000;
  padding: 2rem;
  text-align: center;
  font-weight: 500;
  width: 100%;
  border: 1px solid #ddd;
}

/* CSS by Naveen Patidar for Pagination only */

.AO-2nd {
  width: calc(100% - 10px);
  margin: 5px 0;
  display: flex;
  justify-content: space-between;
  font-size: 13px;
}

.AO-2nd-ent-select {
  width: 100%;
  margin: 0 0.85rem;
  padding: 0 0.35rem;
  width: 4rem;
  height: 30px;
  border: 1px solid rgba(0, 0, 0, 0.158);
  border-radius: 0.25rem;
}

.AO-2nd-ent-select:focus {
  border: 1px solid rgba(0, 0, 0, 0.158);
}

.AO-2nd-ent-select::after {
  border: 1px solid rgba(0, 0, 0, 0.158);
}

.AO-2nd-search {
  margin-right: 1rem;
}

.AO-search {
  padding: 0.25rem 0.5rem;
  border: 1px solid rgba(0, 0, 0, 0.384);
  border-radius: 0.25rem;
  font-size: 18px;
}

.AO-search:focus {
  transition: 0.2s;
  outline: 2px solid rgb(55, 189, 241);
}

.AO-search::placeholder {
  font-size: 15px;
}

.AO-2nd-entries {
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: center;
}

.Page {
  display: flex;
  justify-content: space-between;
  font-size: 13px;
  align-items: center;
  padding: 0.5rem;
  margin-top: 1rem;
}

.page-2nd {
  display: flex;
  justify-content: center;
  align-items: center;
}

.page-item {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.5rem 0.75rem;
  cursor: pointer;
  border: 1px solid rgba(0, 0, 0, 0.158);
}

.page-prev,
.page-next {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.5rem;
  cursor: pointer;
  border: 1px solid rgba(0, 0, 0, 0.158);
}

.active {
  background-color: #0971fe !important;
  color: white !important;
}

.page-icon2 {
  display: none;
}
.page-wrap {
  width: 25% !important;
}
@media screen and (max-width: 500px) {
  .AO-2nd {
    margin: 0.5rem 0;
  }
  .page-icon1 {
    display: none;
  }
  .page-icon2 {
    display: block;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.1rem 0.15rem;
  }
  .page-wrap {
    width: 50% !important;
  }
  .AO-2nd-ent-select {
    margin: 0 0.5rem;
  }
  .show-list {
    max-width: 100%;
  }
  .Page {
    gap: 1rem;
    flex-direction: column;
  }
}

@media screen and (max-width: 300px) {
  .show-list {
    max-width: 100%;
  }

  .AO-2nd-ent-select {
    width: 3rem;
  }
  .page-wrap {
    width: 40% !important;
  }
}
