.login-div {
  /* height: 100; */
  width: 100%;
  background-color: #f5f6fa;
  justify-content: center;
  align-items: center;
  display: flex;
}

/* .login-inner-div {
  background-color: #fff;
  padding: 20px;
  border-radius: 15px;
  max-width: 550px;
  width: 100%;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
} */
.login-text {
  text-align: center;
  font-size: 20px;
  font-weight: 600;
}

.pass-show-eye {
  position: absolute;
  right: 10px;
  font-size: 20px;
  bottom: 2px;
  cursor: pointer;
}

.forgot-content {
  font-size: 13px;
  padding: 0.25rem 1rem;
  text-align: center;
}

