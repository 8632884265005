.payruns_modal_upper_heading {
  display: flex;
  gap: 15px;
}

.payruns_modal_main_container {
  box-shadow: rgb(187, 187, 187) 2px 6px 10px 6px;
  padding: 20px 0;
  width: 370px;
  position: absolute;
  right: 0;
  height: 88vh;
  top: 10%;
  background-color: white;
}

.payruns_modal_inner_inner_div {
  display: flex;
  padding: 10px 5px;
}

.payruns_modal_description_right {
  margin-left: auto;
}

.payruns_modal_heading_container {
  border-bottom: 2px solid #6c757d;
  padding: 0 20px;
  padding-bottom: 20px;

}

.payruns_modal_middle_container {
    padding: 0 20px;
  padding-top: 20px;
  height: 70%;
  /* overflow-x: hidden; */
  overflow-y: scroll;
}

.payruns_modal_middle_container::-webkit-scrollbar-thumb{
    width: 40px;
}

.payruns_modal_description {
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: transparent;
  --blue: #007bff;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #dc3545;
  --orange: #fd7e14;
  --yellow: #ffc107;
  --green: #28a745;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: #fff;
  --gray: #6c757d;
  --gray-dark: #343a40;
  --primary: #007bff;
  --secondary: #6c757d;
  --success: #28a745;
  --info: #17a2b8;
  --warning: #ffc107;
  --danger: #dc3545;
  --light: #f8f9fa;
  --dark: #343a40;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI",
    Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol";
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas,
    "Liberation Mono", "Courier New", monospace;
  -webkit-font-smoothing: antialiased;
  color: #6f6f6f;
  line-height: 24px;
  font-family: "IBM Plex Sans", sans-serif;
  font-weight: 500;
  border-spacing: 0;
  border-collapse: collapse;
  box-sizing: border-box;
  list-style: none;
  -webkit-transition-timing-function: ease-in-out;
  -webkit-transition-duration: 0.2s;
  padding: 0 !important;
  font-size: 15px;
  width: 50%;
  padding-top: 10px;
  padding-left: 30px;
  padding-right: 10px;
  text-align: left;
  border-bottom-color: initial;
}

.payruns_modal_description_right {
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: transparent;
  --blue: #007bff;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #dc3545;
  --orange: #fd7e14;
  --yellow: #ffc107;
  --green: #28a745;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: #fff;
  --gray: #6c757d;
  --gray-dark: #343a40;
  --primary: #007bff;
  --secondary: #6c757d;
  --success: #28a745;
  --info: #17a2b8;
  --warning: #ffc107;
  --danger: #dc3545;
  --light: #f8f9fa;
  --dark: #343a40;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI",
    Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol";
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas,
    "Liberation Mono", "Courier New", monospace;
  -webkit-font-smoothing: antialiased;
  color: #6f6f6f;
  line-height: 24px;
  font-family: "IBM Plex Sans", sans-serif;
  font-weight: 500;
  border-spacing: 0;
  border-collapse: collapse;
  box-sizing: border-box;
  list-style: none;
  -webkit-transition-timing-function: ease-in-out;
  -webkit-transition-duration: 0.2s;
  padding: 0 !important;
  font-size: 15px;
  text-align: center !important;
  width: 50%;
  padding-top: 10px;
  padding-left: 30px;
  padding-right: 10px;
  text-align: left;
  border-bottom-color: initial;
  font-weight: 600 !important;
  font-size: 15px !important;
}
