.auth-input-row {
  display: flex;
  gap: 20px;
  margin-top: 10px;
}
.login-inner-div {
  background-color: #fff;
  padding: 20px;
  border-radius: 15px;
  max-width: 550px;
  width: 100%;
  height: auto;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  margin: 10px;
}

/* styles.css */

/* Small screens (up to 576px) */
@media (max-width: 576px) {
  .auth-input-row {
    display: flex;
    gap: 10px;
    flex-direction: column;
  }
}

/* Medium screens (577px to 992px) */
@media (min-width: 577px) and (max-width: 992px) {
}

/* Large screens (993px and above) */
@media (min-width: 993px) {
}



.modal-body {
  text-align: left;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  gap: .5rem;
  max-height: 60vh !important;
}
.modal-body::-webkit-scrollbar{
  display: none;
}
.modal-body > p {
  font-size: 14px;
    color: #6f6f6f;
    font-weight: 400;
    line-height: 24px;
}
.model-h3 {
  font-size: 1.25rem;
  letter-spacing: -0.01rem;
  font-family: "DM Sans",sans-serif;
  font-weight: 500;
  line-height: 21px;
  margin-bottom: 5px;
}