.profile_container {
  /* padding: 20px; */
  justify-content: center;
  align-items: center;
  display: block;
  /* max-width: 100%; */
  margin: auto;
}
.profile_head{
  padding: 20px;
  /* min-width: 100vw; */
  /* width: calc(100vw - 16rem - 40px); */
  width: 100%;
}
.title_main {
  font-size: 25px;
  font-weight: 500;
  color: #364a63;
}
.subtitle_main {
  margin-top: 10px;
  font-size: 16px;
  font-weight: 500;
  color: #364a63;
}
.card_heading {
  font-size: 20px;
  font-weight: 500;
  color: #000;
}
.p_card_div {
  padding: 20px;
  margin: auto;
  /* max-width: 80%; */
  background: #fff;
}
.p_card_header {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 2rem;
}

.p_card {
  /* box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px; */
  padding-inline: 20px;
  padding-block: 15px;
  margin-top: 15px;
  border-radius: 10px;
  background-color: #fff;
}
.p_data_row {
  display: flex;
  gap: 10px;
  align-items: center;
  margin-block: 10px;
  margin-inline: 15px;
}
.p_row_title {
  font-size: 14px;
  font-weight: 400;
  color: #8094ae;
  flex: 1;
}
.p_row_title_profile {
  font-size: 14px;
  font-weight: 400;
  color: #000;
  flex: 1;
}

.p_row_value_profile {
  font-size: 14px;
  font-weight: 500;
  color: #000;
  flex: 1.5;
}
.p_row_line {
  height: 0.5px;
  background-color: #8094ae;
}

/* u info css */
.u_title {
  font-size: 20px;
  font-weight: 400;
  color: #8094ae;
}
.u_value {
  font-size: 20px;
  font-weight: 500;
  color: #6f6f6f;
  flex: 1.5;
}
.u_radio_wrap {
  display: flex;
  gap: 20px;
  margin-top: 10px;
}

/* radio btn css */
.p_radio_main {
  display: flex;
  align-items: center;
  gap: 7px;
  cursor: pointer;
}
.p_radio_btn {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  border: 2px solid #888;
  cursor: pointer;
}
.p_radio_active_btn {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  border: 2px solid #2cbfca;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  display: flex;
}
.p_radio_active_btn_inner {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: #2cbfca;
}
.p_radio_txt {
  font-weight: 500;
  font-family: "IBM Plex Sans", sans-serif;
  font-size: 14px;
  color: #6f6f6f;
  line-height: 24px;
}

/* invoice css */
.invoice_card {
  display: flex;
  /* box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px; */
  padding-inline: 20px;
  padding-block: 15px;
  margin-top: 15px;
  border-radius: 10px;
  background-color: #fff;
  gap: 20px;
}
.invoive_txt {
  font-size: 14px;
  font-weight: 500;
  color: #6f6f6f;
  flex: 1.5;
}
.invoice_btn_wrap {
  display: flex;
  gap: 10px;
  align-items: center;
}

/* setting information */
.setting_heading {
  font-size: 18px;
  line-height: 26px;
  color: #000;
  font-weight: 400;
}
.setting_sub_heading {
  font-size: 14px;
  color: #6f6f6f;
  font-weight: 400;
  line-height: 24px;
  display: flex;
  gap: 10px;
}
.setting_inner_row {
  display: flex;
  gap: 80px;
  align-items: center;
  padding: 10px;
}
.setting_btn_wrap {
  display: flex;
  align-items: center;
  gap: 10px;
}
.profile_icon{
  display: none;
}
@media (max-width: 906px) {
  .profile_icon{
    display: block;
  }
  /* .profile_head{
    width: calc(100vw - 20px);
  } */
}

@media screen and (max-width: 800px) {
  .profile_container {
    max-width: 100%;
  }
  .p_card_div {
    max-width: 100%;
  }


  .u_radio_wrap {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, auto);
  }
}

@media screen and (max-width: 500px) {
  .invoice_card {
    flex-direction: column;
  }
  .invoice_btn_wrap {
    width: 100%;
    justify-content: space-between;
  }
  .u_radio_wrap {
    padding: 0 .5rem;
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: repeat(1, auto);
  }
  .setting_inner_row {
    flex-direction: column;
    gap: 20px;
  }
  .setting_btn_wrap {
    justify-content: flex-end;
  }
  .setting_sub_heading{
    flex-direction: column;
  }
  .p_card{
    padding-inline: 0;
  }
  .p_row_title{
    font-size: 12px;
  }
  .p_row_value {
    font-size: 12px;
  }
  .p_data_row{
    align-items: flex-start;
  }
  .card_heading,.u_title{
    padding: 0 .5rem;
    font-size: 16px;
  }
  .setting_heading{
    font-size: 14px;
  }

  .p_card_header {
   
    justify-content: space-between;
  }
}
